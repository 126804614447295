<template>
  <main>
    <package-header title="Applications" sub-title="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-10">

          <router-link class="d-inline-block mb-3" to="/pages/applications">
            <svg class="icon icon-arrow_cta_back mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
            Return to Applications
          </router-link>

          <h2>Select an application to add to your package</h2>

          <h4 class="mt-4">For Legal Professionals</h4>

          <ul class="list-group">
            <li class="list-group-item" v-for="(application, index) in applications" :key="index">
              <div class="d-sm-flex justify-content-between align-items-center">
                <h5 class="mb-1 mb-sm-0 pr-2">
                  {{ application }}
                </h5>
                <div class="text-nowrap">
                  <button class="btn btn-tertiary btn-sm mr-1">Add</button>
                  <label class="mb-0" :for="index">
                    <span class="btn btn-secondary btn-sm">Add From Template</span>
                  </label>
                  <input accept=".wff" class="d-none" type="file" :id="index">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="bg-medium py-2 fixed-bottom" v-if="applicationAdded">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 d-sm-flex justify-content-between align-items-center">
              <div class="mr-3 mb-2 mb-sm-0">
                <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
                {{ applicationSelected }} has been successfully added to your package.
              </div>
              <button class="btn btn-primary">Finish</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Add Applications"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../components/PackageHeader.vue";
import HelpPanel from "../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "help-panel": HelpPanel
  },

  data() {
    return {
      applicationAdded: false,
      applicationSelected: "",
      names: false,
      common: false,
      applications: [
        "Claim of Builders Lien",
        "Declaration",
        "Form 17 (Cancellation of Charge, Notation or Filing)",
        "Form 17 (Charge, Notation or Filing)",
        "Form 17 (Fee Simple)",
        "Form A (Freehold Transfer)",
        "Form B (Mortgage)",
        "Form C (Charge)",
        "Form C (Release)",
        "PTT Return",
        "Strata Property Act Filing"
      ]
    };
  },

  methods: {
    addApplication: function(name) {
      this.applicationAdded = true;
      this.applicationSelected = name;
    },
    toggleNames: function() {
      this.names = !this.names;
    },
    toggleCommon: function() {
      this.common = !this.common;
    }
  }
};
</script>
